/** Services.tsx **/

.superParentHide, .superParentShow {
    /* padding-top: 3em; */
    /* background-color: #F7F7F7; */
    /* padding-bottom: 3em; */
}

.superParentShow {
    animation: fadeIn 1s;
}

.superParentHide {
    opacity: 0;
    animation: fadeOut 0.5s;
}

.parent {
    padding-top: 2em;
    padding-bottom: 2em;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.mobileParent {
    display: none;
}

.serviceGraphicContainer {
    margin-left: 2em;
    margin-right: 2em;
}

@media only screen and (max-width: 900px) {

    .superParentHide, .superParentShow {
        background-color: white;
        position: relative;
        bottom: 5em;
        padding-bottom: 0em;
        padding-top: 5em;
    }
    
    .parent {
        display: none;
    }

    .mobileParent {
        display: block;
    }

}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; } 
}

@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
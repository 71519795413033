/* PortfolioItem.tsx */

.imageContainer img {
    width: 50%;
}

.textContainer {
    margin-top: -1em;
    margin-bottom: 2em;
}

.textClient, .textProject {
    font-size: 1.2em;
    text-decoration: none;
}

.textClient {
    margin-bottom: 0.5em;
}

.textProject {
    font-weight: bold;
    color: #0057F0;
}

.testimonialContainer {
    width: 70%;
    margin: auto;
    color: #0057F0;
    margin-top: -0.5em;
    margin-bottom: 2em;
}

.testimonialContainer p {
    font-size: 0.8em;
}

.testimonialName {
    font-weight: bold;
}

.testimonialText {
    background-color: #F7F7F7;
    padding: 1em;
    border-left: #0057F0 solid 0.5em;
}

.testimonialRole {
    position: relative;
    bottom: 0.8em;
    /* font-weight: bold; */
    font-size: 0.7em !important;
}

@media only screen and (max-width: 811px){
    .imageContainer img {
        width: 100%;
    }

    .textClient, .textProject {
        margin-bottom: 0.5em;
        font-size: 0.75em;
    }

    .testimonialContainer {
        margin-bottom: 2em;
    }

    .testimonialContainer p {
        font-size: 0.6em;
    }
    
}
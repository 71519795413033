/** Landing **/

.parent {
    margin-left: 5em;
    padding-top: 2em;
    padding-bottom: 3em;
}

.container {
    width: 100%;
    display: inline-block;
}

.container .text {
    font-size: 0.85em;
    margin-left: 6em;
    text-align: left;
    width: 35em;
}

.logo {
    position: relative;
    right: 2.7em;
    margin-top: 5em;
    width: 25em;
    object-fit: contain; 
}


.logoMobile {
    width: 55%;
    display: none;
}

.logoTextMobile {
    display: none;
}

.buttonContainer {
    width: 10em;
    margin-left: 5.5em;
    margin-top: 3em;
}

.textButtonContainer {
    background-color: none;
}

@media only screen and (max-width: 1200px) {

    .parent {
        margin-left: auto;
        margin-right: auto;
    }

    .container {
        width: 70%;
        display: block;
        margin-left: auto;
        margin-right: auto
    }

    .container .text {
        font-size: 0.7em;
        margin-left: 0em;
        text-align: center;
        width: 100%;
    }

    .logo {
        width: 100%;
        right: 0em;
    }

    .buttonContainer {
        width: 10em;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3em;
    }

}

@media only screen and (max-width: 400px)   {
    
    .logo {
        display: none;
    }

    .logoMobile {
        margin-top: 1em;
        width: 45%;
        display: inline-block;
    }

    .logoTextMobile {
        width: 80%;
        margin-top: 2em;
        display: inline-block;
    }

    .waveHand {
        display: none;
    }

    .container {
        text-align: center;
        width: 100%;
        padding-top: 2em;
    }

    .container .text {
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        text-align: center;
        font-size: 0.8em;
    }

    .buttonContainer {
        margin-left: auto;
        margin-right: auto;
    }

    .textButtonContainer {
        background-color: #F7F7F7;
        margin-top: 6em;
        padding-top: 3em;
        padding-bottom: 3em;
        padding-left: 0.3em;
        padding-right: 0.3em;
    }

}
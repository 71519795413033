/** NavBar **/

.parent {
    z-index: 2;
    background-color: white;
    width: 100%;
    position: fixed;
    padding-bottom: 1.3em;
}

.logoContainer {
    cursor: pointer;
}

.containerOpen {
    display: flex;
    justify-content: space-between;
    float: right;
}

.containerClose {
    display: none;
    animation: fadeOut 1s;
}

.logoShow, .logoHide {
    width: 12em;
    position: absolute;
    top: 0.8em;
    left: 3em;
}

.logoHide {
    position: relative;
    opacity: 0;
    animation: fadeOut 0.5s;
    display: none;
}

.logoShow {
    animation: fadeIn 0.5s;
}

.pagesClose, .pagesOpen {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 2em;
    margin-right: 3em;
    animation: fadeIn 1s;
    opacity: 1; 
}

.pagesOpen a, .pagesClose a {
    margin-left: 1.5em;
    margin-right: 1.5em;
    cursor: pointer;
    font-size: 0.8em;
    color: black;
    transition: ease 0.2s;
    text-decoration: none;
}

.pagesOpen a:hover, .pagesClose a:hover {
    color: grey;
    transition: ease 0.2s;
}

.burgerContainerOpen, .burgerContainerClose {
    display: none;
}

@media only screen and (max-width: 1200px) {
    
    .containerOpen {
        flex-direction: column;
        float: none;
    }

    .logoContainer {
        position: relative;
        top: 1.4em;
        width: 10em;
        /* left: 0.8em; */
        right: 0.5em;
        margin-left: auto;
        margin-right: auto;
    }

    .logoShow, .logoHide {
        position: static;
        width: 10em;
        /* top: 5em; */
        left: 0em;
        margin-bottom: 0.1em;
    }

    .logoHide {
        display: block;
        opacity: 0;
    }

    .pagesOpen, .pagesClose {
        flex-direction: column-reverse;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0em;
    }

    .pagesOpen {
        margin-top: -1em;
    }

    .pagesOpen a, .pagesClose a {
        margin-top: 2em;
        text-align: center;
    }

    .burgerContainerClose {
        margin-top: -1em;
        margin-right: 1em;
        float: right;
        display: block;
    }

    .burgerContainerOpen {
        margin-top: 3em;
        margin-right: auto;
        float: none;
        display: block;
    }
    
}

/* Animations */

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
/** ServiceGraphic.tsx **/

.parent {
    display: flex;
    width: 100%;
}

.circle {
    width: 7em;
    height: 7em;
    border-radius: 100em;
}

.graphic {
    width: 8.5em;
    position: relative;
    right: 0.8em;
    bottom: 0.6em;
}

.headingContainer, .textContainer {
    width: 21em;
    margin-left: 1.5em;
    margin-top: -0.3em;
}

.heading {
    font-weight: bold;
}

.text {
    margin-top: -0.5em;
    font-size: 0.8em;
}

@media only screen and (max-width: 1500px) {
    
    .parent {
        width: 20em;
    }

    .headingContainer, .textContainer {
        width: 100%;
        margin-left: 1em;
        margin-top: 0em;
    }

    .textContainer .text {
        font-size: 0.8em;
    }

}

@media only screen and (max-width: 1100px) {
    
    .parent {
        width: 15em;
    }

    .headingContainer, .textContainer {
        width: 100%;
        margin-left: 1em;
        margin-top: 0em;
    }

    .textContainer .text {
        font-size: 0.8em;
    }

}

@media only screen and (max-width: 900px) {
    
    .parent {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3em;
        margin-bottom: 2em;
        flex-direction: column;
        text-align: center;
    }

    .circle {
        margin-left: auto;
        margin-right: auto;
    }
    


    .headingContainer, .textContainer {
        width: 80%;
        margin-left: 0em;
        margin: auto;
    }

    .textContainer .text {
        font-size: 0.8em;
    }

}

@media only screen and (max-width: 400px){

    .parent {
        margin-top: 0em;
        width: 100%;
        height: 15em;
    }

    .graphic {
        width: 6em;
        position: relative;
        left: 0.1em;
        top: 1.5em;
        bottom: 0em;
    }

    .headingContainer, .textContainer {
        width: 100%;
    }

    .textContainer .text {
        font-size: 0.6em;
    }

}
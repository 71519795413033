/* PortfolioCarousel.tsx */

.parent {
    text-align: center;
    padding-bottom: 4em;
    padding-top: 3em;
}

.enquireNowContainer {
    width: 10em;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3em;
}

@media only screen and (max-width: 980px) {

    .parent {
        padding-top: 1em;
    }

}
/* Contact.tsx */

.parentShow, .parentHide {
    padding-bottom: 6em;
    padding-top: 2em;
}

.parentShow {
    opacity: 1;
    animation: fadeIn 1s;
}

.parentHide {
    opacity: 0;
    animation: fadeOut 1s;
}

.headerContainer {
    text-align: center;
    margin-top: -3em;
    margin-bottom: 4em;
}

.inputContainer {
    text-align: center;
}

.nameInputContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 1.3em;
}

.emailInputContainer {
    margin-bottom: 1.3em;
}

.header {
    font-weight: bold;
    font-size: 2em;
}

.subheader {
    margin-top: -0.7em;
    font-size: 1.5em;
}

.nameInputContainer input {
    width: 12em;
    margin-left: 1em;
    margin-right: 1em;
}

.emailInputContainer input {
    width: 27em;
}

.tellUsInputContainer textarea {
    width: 27em;
    padding-bottom: 3em;
}

.buttonParent {
    display: flex;
    justify-content: center;
}

.buttonContainer {
    margin-top: -1em;
    margin-left: 1em;
    margin-right: 1em;
}

@media only screen and (max-width: 700px){

    .parentShow, .parentHide {
        padding-bottom: 3em;
        padding-top: 4em;
    }

    .header {
        font-weight: bold;
        font-size: 1.5em;
    }

    .subheader {
        margin-top: -1em;
        font-size: 0.9em;
    }

    .headerContainer {
        text-align: center;
        margin-bottom: 2em;
    }

    .nameInputContainer {
        flex-direction: column;
        width: 100%;
    }

    .emailInputContainer {
        margin-top: -1.2em;
    }

    .nameInputContainer input {
        width: 80%;
        margin-bottom: 1em;
        margin-left: 0em;
        margin-right: 0em;
    }

    .emailInputContainer input {
        width: 80%;
    }

    .tellUsInputContainer textarea {
        width: 80%;
        padding-bottom: 3em;
    }

    .buttonParent {
        flex-direction: column;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .buttonParent p {
        font-size: 0.65em;
        top: 0.15em;
    }

    .buttonContainer {
        margin-left: 0em;
        margin-right: 0em;
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

}

/* Animations */

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
/* Button.tsx */

.container {
    text-decoration: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 2em;
    width: 100%;
    border-radius: 100em;
    padding-bottom: 0.3em;
    transition: 0.3s;
}

.container:hover{
    background-color: white !important;
    transition: 0.3s;
}

.container:hover .text {
    color: var(--hover-color) !important;
}

.container:hover .iconContainer {
    color: black;
}

.iconContainer {
    color: white;
    position: relative;
    top: 0.7em;
    padding-left: 1em;
}

.text {
    color: white;
    font-weight: bold;
    position: relative;
    bottom: 0.40em;
    padding-right: 0.5em;
    padding-left: 0.5em;
}
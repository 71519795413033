/* CarouselTabs.tsx */

.container {
    display: flex;
    justify-content: center;
}

.tabActive, .tabInactive {
    margin-left: 0.5em;
    margin-right: 0.5em;
    width: 0.5em;
    height: 0.5em;
    border-radius: 100em;  
}

.tabActive {
    opacity: 1;
}

.tabInactive {
    opacity: 0.2;
}